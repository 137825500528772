<template>
  <div class="font-montserrat top">
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold  text-center text-oonpaytext text-3xl">
          {{ response.fullName }}
        </h4>
      </div>
    </div>

    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>
    <!-- {{response.item1 && response.item1[0] }} -->
    <div v-else class="container-fluid  mt-5 p-5">
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-md-6" v-if="response">
              <img
                v-if="imgurl"
                :src="
                  `${ImagebaseUrl}/oonpayhealthhubsetup/${imgurl}`
                "
                alt=""
              />
              <img
                v-else
                src="https://placehold.jp/1000x750.png
                "
                alt=""
              />
            </div>
            <div class="col-md-6">
              <h4>{{ response.fullName }}</h4>
              <p>
                {{ response.description }}
              </p>

              <h5>
                Package Unit Cost: {{ currency }}
                {{ Math.round(response.cost * 10) / 10 }}
              </h5>
              <h6 class="text-lg">
                {{ response.merchantStreetAddress }}
              </h6>

              <p>{{ Math.round(response.distanceAway / 1000, 2) }} KM Away</p>

              <div class="row">
                <div class="col-md-5">
                  <button class="btn btn-outline-danger">
                    Expires in
                    {{ response.expirationQuantity }}
                    {{ response.expirationQuantityUnit }} After Purchase
                  </button>
                </div>
                <div
                  class="col-md-6"
                  v-if="countryISOCode == 'GHS' || countryISOCode == 'NG'"
                >
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    @click="canpackagebepurchase"
                  >
                    Make Payment
                  </button>

                  <paystack
                    style="opacity: 0;"
                    class="btn btn-danger submit  btn-block"
                    :amount="total * 100"
                    :email="email"
                    :paystackkey="GH_PUBLIC_KEY"
                    :reference="reference"
                    :callback="processPayment"
                    :close="close"
                    currency="GHS"
                    :channels="channels"
                    v-if="countryISOCode == 'GHS'"
                    :metadata="metadata"
                    :disabled="disabled"
                  >
                    Proceed to checkout
                  </paystack>

                  <paystack
                    style="opacity: 0;"
                    class="btn btn-danger logout btn-block"
                    :amount="total * 100"
                    :email="email"
                    :paystackkey="NG_PUBLIC_KEY"
                    :reference="reference"
                    :callback="processPayment"
                    :close="close"
                    currency="NGN"
                    :channels="channels"
                    :metadata="metadata"
                    v-if="countryISOCode == 'NGN'"
                  >
                    Proceed to checkout
                  </paystack>
                </div>

                <div v-else class="col-md-6">
                  <button
                    type="button"
                    class="btn btn-block btn-info"
                    @click.prevent="generatePaymentIntent"
                  >
                    Make Payment
                  </button>
                </div>
                <!-- Stripe payment -->
                <div
                  class="modal fade"
                  id="makePaymentModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="makePaymentModalTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Payment information
                        </h5>

                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body ">
                        <div v-if="elementsOptions.clientSecret">
                          <stripe-element-payment
                            ref="paymentRef"
                            :pk="pk"
                            :elements-options="elementsOptions"
                            :confirm-params="confirmParams"
                          />
                          <button
                            class="btn btn-primary mt-3 btn-block"
                            @click.prevent="pay"
                          >
                            Pay Now
                          </button>
                        </div>
                      </div>
                      <div class="modal-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paystack from "vue-paystack";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import $ from "jquery";
import { ImagebaseUrl, config, baseUrl } from "../../config";
import { PackPurchaseJson } from "../../helper";

const NG_PUBLIC_KEY = config.NG_PUBLIC_KEY;
const PK = config.PK;
const GH_PUBLIC_KEY = config.GH_PUBLIC_KEY;
const webKey = config.webKey;

export default {
  components: { paystack, FadeLoader, StripeElementPayment },

  data() {
    return {
      ImagebaseUrl,
      response: [],
      currency: JSON.parse(localStorage.getItem("account_info")).countryCurrency,
      pk: PK,
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: ""
      },
      imgurl: localStorage.getItem("imgurl"),
      getInvoiceDetails: {},
      NG_PUBLIC_KEY: NG_PUBLIC_KEY,
      GH_PUBLIC_KEY: GH_PUBLIC_KEY,
      metadata: {
        benefactorGUID: JSON.parse(localStorage.getItem("account_info")).guid,
        webKey: webKey,
        paymentTypeId: 4,
        healthHubPackPurchaseJson: JSON.stringify(PackPurchaseJson),
        clientGUID: localStorage.getItem("clientguid"),
        
      },
      disabled: false,
      message: "",
      quantity: 1,
      full_name: null,
      email: JSON.parse(localStorage.getItem("account_info")).email,
      countryISOCode: "US",
      confirmParams: {
        return_url: `${baseUrl}/view-healthhub-purchased-packs` // success url
      },
      paymentReceiptEmail: "",
      loading: false,
      color: "#0b4369",
      channels: ["card", "bank", "mobile_money", "bank_transfer"]
    };
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    total() {
      localStorage.setItem("PurchaseQuantity", this.quantity);
      return this.response.cost * this.quantity;
    }
  },

  mounted() {
    this.countryISOCode = localStorage.getItem("clientCountryISO");
    localStorage.setItem("activeTab", localStorage.getItem("BeneficiaryTypeId"))
    const payload = {
      latitude: localStorage.getItem("latitude"),
      longitude: localStorage.getItem("longitude"),
      packageCountryId: localStorage.getItem("countryId"),
      packageGUID: localStorage.getItem("packageGUID"),
      clientCountryISO: localStorage.getItem("clientCountryISO"),
      packageSubCategoryId: localStorage.getItem("packageSubCategoryId")
    };
    this.loading = true;
    this.$store
      .dispatch("healthhubclient/SearchPackDetail", payload)
      .then((res) => {
        if (res.status == 200) {
          this.response = res.data.item1;
          console.log(this.response )
          this.loading = false;
        }
      }) .catch((err) => {
          this.handleError(err)
        });
  },

  methods: {
    handleError(err) {
      if (localStorage.getItem("BeneficiaryTypeId") == null) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Beneficiary Type was not provided"
        });
      } else if (localStorage.getItem("countryId") == null) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Beneficiary Country was not provided"
        });
      }else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: err.response.data
        });
      }
    },

    canpackagebepurchase() {
      const payload = {
        packageGUID: this.response.guid,
        benefactorGUID: localStorage.getItem("clientguid"),
        packageQuantity: this.quantity,
        beneficiaryCountryId: localStorage.getItem("countryId"),
        beneficiarytypeid: localStorage.getItem("BeneficiaryTypeId"),
        beneficiaryPhoneNumber:
          localStorage.getItem("BeneficiaryTypeId") == 3
            ? JSON.parse(localStorage.getItem("account_info")).phoneNumber
            : localStorage.getItem("BeneficiaryPhoneNumber"),
        ignoreExistingUnusedPackageRule: "false"
      };

      this.$store
        .dispatch("healthhubclient/canpackagebepurchase", payload)
        .then((response) => {
          //console.log(response);
          if (response.data == true) {
            //console.log(this.metadata);
            if (this.countryISOCode == "GHS") {
              document.querySelector(".submit").click();
              
            } else {
              document.querySelector(".logout").click();
            }
          } else {
            this.$swal({
              icon: "info",
              title: "Oops...",
              text:
                "Package already exist! It has to be used in order to purchase another"
            });
          }
        })
        .catch((err) => {
          this.handleError(err)
        });
    },

    async generatePaymentIntent() {
      const payload = {
        paymentTypeId: 3,
        healthHubPackPurchaseJson: JSON.stringify(PackPurchaseJson)
      };

      // console.log(payload);

      try {
        const res = await this.$store.dispatch("invoice/getSecurite", payload);
        if (res.data) {
          this.elementsOptions.clientSecret = await res.data;
          await $("#makePaymentModal").modal("show");
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data
        });
        console.error('Error generating payment intent:', error);
        // You may want to add some user notification or error handling here
      }
    },

    pay() {
      this.$refs.paymentRef.submit();
    },

    // Paystack
    processPayment: () => {
      location = "/view-healthhub-purchased-packs";
    },
    close: () => {
      console.log("You closed checkout page");
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

.other-img {
  width: 100%; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

.bg-warning {
  background: #e87c34 !important;
  color: aliceblue;
  font-weight: 500;
}
</style>
