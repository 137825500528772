
export const PackPurchaseJson = {
    BenefactorGUID: localStorage.getItem("clientguid"),
    PackageGUID: localStorage.getItem("packageGUID"),
    BeneficiaryTypeId:
      localStorage.getItem("BeneficiaryTypeId") == "myself"
        ? 3
        : localStorage.getItem("BeneficiaryTypeId"),
  
    BeneficiaryFullName:
      localStorage.getItem("BeneficiaryTypeId") == "myself"
        ? JSON.parse(localStorage.getItem("account_info")).firstName +
          " " +
          JSON.parse(localStorage.getItem("account_info")).lastName
        : localStorage.getItem("BeneficiaryFullName"),
    BeneficiaryPhoneNumber:
      localStorage.getItem("BeneficiaryTypeId") == "myself"
        ? JSON.parse(localStorage.getItem("account_info")).phoneNumber
        : localStorage.getItem("BeneficiaryPhoneNumber"),
    BeneficiaryCountryId: localStorage.getItem("BeneficiaryCountryId"),
    PaymentChanneld: 2,
    PurchaseQuantity: 1,
    email: JSON.parse(localStorage.getItem("account_info")).email,
    IgnoreExistingUnusedPackageRule: false
  };